// types
import type { WopsReturnOrderInterface } from '@/classes/returnOrder';
import type { WopsReturnOrderLineInterface } from '@/classes/returnOrderLine';
import type { ProductItem } from '@/types/product';

// helpers
import { getFirstImageAsset, ProductAssetsKindByCategory } from '@/helpers/asset';
import { getProductStockStatusId } from '@/helpers/product';
import { WopsProductInterface } from '@/classes/product';
import PurchaseStock from 'whirli-client/dist/Interfaces/stock/PurchaseStock';

// constants
import { AssetKind, AssetDocumentCategory } from '@/constants/products/assets';

export function getReturnOrderItems(returnOrder: WopsReturnOrderInterface): ProductItem[] {
  const returnOrderLines: WopsReturnOrderLineInterface[] = returnOrder.returnOrderLines || [];
  const returnOrderItems: ProductItem[] = returnOrderLines.flatMap((line: WopsReturnOrderLineInterface) => {
    return {
      id: line?.stock?.id,
      name: line?.stock?.productVariant?.product?.name,
      image: getFirstImageAsset(line?.stock?.productVariant?.product as WopsProductInterface),
      sku: line?.stock?.productVariant?.sku,
      slug: line?.stock?.productVariant?.product?.slug,
      productStatus: line?.stock?.productVariant?.product?.status,
      comment: '',
      instuctionsPDFs: ProductAssetsKindByCategory(
        line?.stock?.productVariant?.product as WopsProductInterface,
        AssetKind.DOCUMENT,
        AssetDocumentCategory.INSTRUCTIONS
      ),
      stockStatus: getProductStockStatusId(line?.stock?.productVariant?.stock),
    } as ProductItem;
  });
  return returnOrderItems;
}

export function getToyboxProductItems(toyboxItems: PurchaseStock[]): ProductItem[] {
  const productItems: ProductItem[] = [];
  let item: ProductItem = {};

  toyboxItems.map((toyboxItem: PurchaseStock) => {
    item.id = toyboxItem.id;
    item.name = toyboxItem.productVariant?.product?.name;
    item.image = getFirstImageAsset(toyboxItem.productVariant?.product as WopsProductInterface);
    item.sku = toyboxItem.productVariant?.sku;
    item.slug = toyboxItem.productVariant?.product?.slug;
    item.productStatus = toyboxItem.productVariant?.product?.status;
    item.stockStatus = getProductStockStatusId(toyboxItem.productVariant?.stock);
    productItems.push(item);
    item = {};
  });
  return productItems;
}

export function createRequestParams(
  processedReturns: ProductItem[],
  comment: string | null,
  images: File[] | null,
  isGoodCondition: boolean
): FormData {
  const formData = new FormData();
  let stockIndex = 0;
  processedReturns.flatMap((returnItem) => {
    formData.append(`stock[${stockIndex}][id]`, String(returnItem.id));
    formData.append(`stock[${stockIndex}][status]`, String(returnItem.status));
    formData.append(`stock[${stockIndex}][comment]`, String(returnItem?.comment || ''));
    stockIndex++;
  });

  if (images && images.length) {
    for (let imagesIndex = 0; imagesIndex < images.length; imagesIndex++) {
      formData.append(`images[${imagesIndex}]`, images[imagesIndex], 'attached-image');
    }
  }

  if (comment) {
    formData.append('comment', JSON.stringify(comment));
  }
  formData.append('is_good_condition', isGoodCondition === true ? '1' : '0');

  return formData;
}
